
#WebSiteServiceIconLinks-card-1-text {
    display:none;
}
#WebSiteServiceIconLinks-card-1:hover #WebSiteServiceIconLinks-card-1-text {
    display:block;
}

#WebSiteServiceIconLinks-card-1:hover {
    cursor: pointer;
}

.card {
    cursor: pointer;
}