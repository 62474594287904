/* Remove default bullets */
ul, #appMenu {
    list-style-type: none;
  }

  /* Remove margins and padding from the parent ul */
  #appMenu {
    margin: 0;
    padding: 0;
  }

  /* Style the caret/arrow */
  .caret {
    cursor: none;
    user-select: none; /* Prevent text selection */
  }

  /* Create the caret/arrow with a unicode, and style it */
  .caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
  }

  /* Rotate the caret/arrow icon when clicked on (using JavaScript) */
  .caret-down::before {
    transform: rotate(90deg);
  }

  .caret_n {
    cursor: none;
    user-select: none; /* Prevent text selection */
  }

  .caret_n::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
  }

  /* Rotate the caret/arrow icon when clicked on (using JavaScript) */
  .caret-down_n::before {
    transform: rotate(90deg);
  }

  /* Hide the nested list */
  .nested {
    display: none;
  }

  /* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
  .active {
    display: block;
  }