@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700');

html, body {
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    font-family: Poppins,helvetica neue,Helvetica,Arial,sans-serif;
    color: #313538;
    background-color: #fff;
}
.main{
    width: 100%;
    margin-top: 130px;
    height: auto;
}
.datatable {
    width: 100%;
    font-size: 0.9rem;
}
#login {
  margin-top: 100px;
}

.loginwrap{
    width: 100%;
    height: 100vh;
}
.loginbox{
    max-width: 400px;
}
.loginbox button, .loginbox a{
    border-color:  #1977cc;
    color:  #1977cc;
}

/* Navbar */
.navbar{
    text-transform: uppercase;
    font-weight: 500;
    font-size:  0.8rem;
    letter-spacing: 0.1rem;
}
.navbar-light{
    background-color: white !important;
}

.nav-menu {
  scrollbar-width: thin;
}
.nav-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: left;
    scrollbar-width: thin;
  }
  
.nav-menu > ul {
    display: flex;
    scrollbar-width: thin;
  }

  /*
  <nav class="nav-menu d-none d-lg-block" style={{background: "white"}}>
  <ul style={{left:0, justifyContent: "center", background: "white"}}>
      <li style={{width:200,whiteSpace:"normal", textAlign: "center", fontWeight:g_id===1?"bold":"normal"} }>Account and System Security</li>
*/

.nav-menu ul li {
    display:inline;
    width: 300px;
    text-align: center;
    position: relative;
    white-space: normal;
    cursor: pointer;
/*    padding: 8px 0 8px 20px; */
}


  .nav-menu a {
    display: block;
    position: relative;
    color: #042b50;
    transition: 0.3s;
    font-size: 16px;
    /*font-family: "Open Sans", sans-serif;*/
    padding: 5px 2px;
    border-bottom: 2px solid #fff;
  }
  
  .nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
    color: #1977cc;
    /*border-color: #1977cc;*/
  }
  
  .nav-menu .drop-down ul {
    display: block;
    position: absolute;
    left: 20px;
    top: calc(100% + 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .nav-menu .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
  }
  
  .nav-menu .drop-down ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #082744;
    border: none;
  }
  
  .nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
    color: #1977cc;
  }
  
  .nav-menu .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
  }
  
  .nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
  }
  
  .nav-menu .drop-down .drop-down > a {
    padding-right: 35px;
  }
  
  div.scrollmenu {
    left: 0;
    justify-content:center;
    background-color: #fff;
    overflow: auto;
    white-space: nowrap;
  }
  
  div.scrollmenu a {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 14px;
    text-decoration: none;
    width:200px;
    text-align: center;
  }
  
  div.scrollmenu a:hover {
    background-color: #777;
  }

  div.scrollsubmenu {
    background-color: #fff;
    overflow: auto;
    white-space: nowrap;
  }
  
  div.scrollsubmenu a {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }
  
  div.scrollsubmenu a:hover {
    background-color: #777;
  }

  @media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down ul {
    width: 100%;
      left: -90%;
    }
    .nav-menu .drop-down .drop-down:hover > ul {
      left: -100%;
    }

  }
  
  /* Login Button */
  .login-btn {
    margin-left: 25px;
    background: #1977cc;
    color: #fff;
    border-radius: 50px;
    padding: 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
  }
  
  .login-btn:hover {
    background: #166ab5;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .login-btn {
      margin: 0 48px 0 0;
      padding: 6px 18px;
    }
  }
  
  /* Mobile Navigation */
  .mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
  }
  
  .mobile-nav-toggle i {
    color: #1977cc;
  }
  
  .mobile-nav {
    position: fixed;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    z-index: 9999;
    overflow-y: auto;
    background: #fff;
    transition: ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    padding: 10px 0;
  }
  
  .mobile-nav * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .mobile-nav a {
    display: block;
    position: relative;
    color: #2c4964;
    padding: 10px 20px;
    font-weight: 500;
    outline: none;
  }
  
  .mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
    color: #1977cc;
    text-decoration: none;
  }
  
  
  .mobile-nav .drop-down > a {
    padding-right: 35px;
  }
  
  .mobile-nav .drop-down ul {
    display: none;
    overflow: hidden;
  }
  
  .mobile-nav .drop-down li {
    padding-left: 20px;
  }
  
  .mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(28, 47, 65, 0.6);
    overflow: hidden;
    display: none;
    transition: ease-in-out 0.2s;
  }
  
  .mobile-nav-active {
    overflow: hidden;
  }
  
  .mobile-nav-active .mobile-nav {
    opacity: 1;
    visibility: visible;
  }
  
  .mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
  }
  
 /* -- Slider -- */
/* force image cover the width of the windows */

.carousel .img-fluid{
    width:100%;
    height: auto;
}
.carousel-caption{
    position: absolute;
    transform: translateY(-50%);
    top: 65%;
}
.carousel-caption .bg-tagline{
    background-color:rgb(25 45 25 /50%);
}
.carousel-caption a{
    color: white;
}
.carousel-caption button.btn-orange{
    background: #f35221;
    border: #f35221;
}
.carousel-caption button.btn-danger{
    background: #f21717;
    border: #f21717;
}
/* 
# why-rbd
*/
.why-rbd .content {
  padding: 80px;
  background: #04070a;;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}

.why-rbd .content h3 {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 30px;
}

.why-rbd .content p {
  margin-bottom: 30px;
  font-size: 1.2rem;
}

.why-rbd .icon-boxes .icon-box {
  text-align: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
  padding: 40px 30px;
  width: 100%;
}

.why-rbd .icon-boxes .icon-box i {
  font-size: 40px;
  color: #1977cc;
  margin-bottom: 30px;
}

.why-rbd .icon-boxes .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  color:#0378f7;;
  margin: 0 0 30px 0;
}

.why-rbd .icon-boxes .icon-box p {
  font-size: 15px;
  color: #504d4d;
  font-size: 1.2rem;
}
.padding{
    padding-bottom: 32px;
}

#footer2 {
    font-size: 0.8rem;
}
.social a{
    padding: 0.8rem;
    font-size: 1.2rem;
}
.fa-facebook{
    color: #3b5998;
}
.fa-twitter{
    color: #00aced;
}
.fa-google-plus-g{
    color: #dd4b39;
}
.fa-instagram{
    color: #517fa4;
}
.fa-youtube{
    color: #bb0000;
}
.fa-youtube:hover {
    color: #d5d5d5;
}
/*---Media Queries --*/
@media (max-width: 992px) {

  }
@media (max-width: 768px) {
  
}
@media (max-width: 576px) {
  
}
/*-- covid page --*/
#covid {
  width: 100%;
  height: auto;
  position: relative;
}
#covid .wrap{
    padding: 105px calc(5% + 15px); 
}
#covid ul {
    list-style: none;
}
#covid ul li {
    font-size: 1.3rem;
    font-weight: 300;
    padding-bottom: 0.7rem;
}
#covid ul li:before {
    content: "\2714\0020";
}
#covid button  a {
    color: white;
}
#covid tr.table-head{
    background-color: #033c79!important;
    color: white;
}
#covid .variant{
    color: #033c79!important;
    text-transform: upper;
}
#covid.line {
    height: 2px;
    border-bottom: 1px #033c79;
}

/*
 * overwrite bootstrap color
 */ 
.bg-primary{
    background-color: #0b294a !important;
}
.text-primary{
    color:  #0b294a !important;
}
.bg-success{
    background-color: #377f52 !important;
}
.text-success{
    color:  #377f52 !important;
}
.bg-info{
    background-color: #088193 !important;
}
.text-info{
    color:  #088193 !important;
}
.bg-secondary{
    background-color: #2377ec !important;
}
.text-secondary{
    color:  #2377ec !important;
}
.bg-warning2{
    background-color: #ea662d !important;
}
.text-warning2{
    color:  #ea662d !important;
}
.bg-note{
    background-color: #129c51 !important;
}
.text-note{
    color: #129c51 !important;
}
.bg-beige{
    background-color: #fafce6 !important;
}
.text-beige{
    color: #fafce6 !important;
}
/*---Firefox Bug Fix --*/
.carousel-item {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}

.cnav{
    padding-left: 0.4rem;
    background-color: #1b1818 !important;
}
.cnav a{
    color: white;
    padding-bottom: 0.4rem;
    padding-left: 0.5rem;
    font-size: 1rem;
}
/*-- service css -- */
.sanger {
    color: #dc3545!important;
}
.down {
    background-image: url("./v2/img/icon/down.png");
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
}

/*--- Fixed Background Image --*/
figure {
  position: relative;
  width: 100%;
  height: 80%;
  margin: 0!important;
}
.fixed-wrap {
  clip: rect(0, auto, auto, 0);
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 }
#fixed {
  background-image: url('./v2/img/mac.png');
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  will-change: transform;
}
/*--- Bootstrap Padding Fix --*/
[class*="col-"] {
    padding: 1rem;
}





/*
Extra small (xs) devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap

Small (sm) devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

Medium (md) devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

Large (lg) devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

Extra (xl) large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }
*/





* {
  box-sizing: border-box;
}
  /* for body { */
  /*background: #12c2e9;*/  /* fallback for old browsers */
  /*background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);*/  /* Chrome 10-25, Safari 5.1-6 */
  /*background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9);*/ /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  

/*
body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: sans-serif;
  background: white;
}


body, html, .App, #root, .outer {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.outer .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}
*/

.menu-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #ddd;
}

.menu-left-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
}

.menu-center-col {
  flex: 1;
  background: #aaa;
  overflow-x: scroll;
}

.menu-right-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7e7;
}

.scrollbar {
  overflow-x: scroll;
  overflow-y: hidden;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

/* custom colors  */
.scrollbar-primary::-webkit-scrollbar {
  background-color: #F5F5F5;
}
.scrollbar-primary {
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-color: #e0e0e0;
/*  scrollbar-color: #4285f4 #f5f5f5;*/
}
.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #e0e0e0;
/*  background-color: #4285F4;*/
}

/* vertical scrollbar */
.v-scrollbar {
  overflow-x: hidden;
  overflow-y: scroll;
}

.v-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.v-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.v-scrollbar-primary::-webkit-scrollbar {
  background-color: #F5F5F5;
}
.v-scrollbar-primary {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: #e0e0e0;
/*  scrollbar-color: #4285f4 #f5f5f5;*/
}
.v-scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #e0e0e0;
/*  background-color: #4285F4;*/
}

.gutter {
  background-color: #e5e7eb;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.gutter.gutter-vertical {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
  cursor: row-resize;
}

/* splitter */
.split-pane-col {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.split-pane-row {
  width: 100vw;
/*  height: 100vh;*/
  display: flex;
  flex-direction: row;
}

.split-pane-top,
.split-pane-bottom,
.split-pane-right,
.split-pane-left {
  flex: 1;
  overflow: hidden;
}

.split-pane-right {
  background-color: #fff;
  background-size: 100% 1.2em;
}

.split-pane-top,
.split-pane-bottom {
  background-color: #ccc;
  text-align: left;
  padding: 2%;
}

.separator-col {
  border: 1px solid #e0e0e0;
  cursor: col-resize;
}

.separator-row {
  border: 1px solid #e0e0e0;
  cursor: row-resize;
}
/*
ul {
  margin-left: -2%;
}

ul li {
  margin: 1rem;
  list-style-type: square;
}

ul a {
  text-decoration: none;
  color: darkblue;
  font-size: large;
}

ul a:hover {
  color: black;
}
*/

blockquote {
  border-left: 10px solid #ccc;
  margin: 5.5em 0 0 0;
  quotes: "\201C""\201D""\2018""\2019";
  padding: 2rem 0 1rem;
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

.quote {
  max-width: 60%;
  text-align: right;
  margin: auto;
  font-style: italic;
}

.quote span {
  font-weight: bold;
}
