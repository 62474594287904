@font-face {
    font-family: D-DINCONDENSED_0;
    src: url("./assets/fonts/D-DINCONDENSED_0.OTF") format("opentype");
}

@font-face {
    font-family: FIRASANS-MEDIUM;
    src: url("./assets/fonts/FIRASANS-MEDIUM.OTF") format("opentype");
}

@font-face {
    font-family: FIRASANS-REGULAR;
    src: url("./assets/fonts/FIRASANS-REGULAR.OTF") format("opentype");
}

@font-face {
    font-family: LATO-MEDIUM;
    src: url("./assets/fonts/LATO-MEDIUM.TTF") format("truetype");
}

@font-face {
    font-family: LATO-MEDIUMITALIC;
    src: url("./assets/fonts/LATO-MEDIUMITALIC.TTF") format("truetype");
}


* {
  margin:0;
  padding:0;
  border:0;
  text-align:left;
}

body {
    text-align:center;
    background-color: #CCC;
    color: #2b2b2b;
    font-size: 14px;
    font-family: 'Helvetica', 'Verdana', 'Arial', 'sans-serif';
    overflow-y: auto;
}

div.clear{ clear: both}
ul#menu, ul#submenu {
    overflow: auto;
    background-color: #EEE;
    list-style-type: none;
    margin: 0;
    padding: 0 10px 0 48px;
}

ul#menu li {
    display: block;
    float: left;
    padding: 10px 0 12px 0;
    margin: 0 48px 0 0;
}

ul#menu li.selected {
    border-bottom: 4px solid #E0E0E0;
}

ul#menu li a {
    border-bottom-width: 1px;
}

ul#submenu {
    background-color: #F8F8F8;
    padding: 10px 10px 16px 48px;
    font-size: 0.9em;
}

div#header, div#main, div#mainbottom, div#swatch_top_banner {
    width:100%;
    min-width: 1250px;
    background:#E6E7E8;
    padding: 0px 0 0 0;
    margin:0 auto;
    overflow:auto;
    position:relative;
    border: 0px;
    border-top-width:0;
    border-bottom-width:1;
}
div#nav {
    margin-top: 110px;
}

div#competent_cells_announcement_banner {
    height:100%;
    padding:0 40% 0 5%;
    text-align:center;
/*    background:transparent url('./assets/images/competentcells_banner.jpeg');*/
    background-size:cover; 
    background-position:center;
} 

h1#banner_title {
    font-family:D-DINCONDENSED_0; 
    font-size:5em; 
    white-space:nowrap; 
    color:#907476; 
    text-align:center;
}
a#getitnow {
    font-family:D-DINCONDENSED_0; 
    font-size:3em; 
    color:white; 
    background-color:#907476; 
    padding: 6px 36px; 
    border-radius:10px;
    text-align: center;
}

div.thirds {
    height:100%; 
    width:100%;
}
div.popout {
    height:100%; 
    width:100%;
}

div#new_swatch {
    height:400px;
    width: 1000px;
    background-image: "./assets/images/competentcells_banner.jpeg";
}

div#header {
    height:120px;
    margin-bottom:0;
    padding-bottom:0; 
    margin-top: -150px;
    background: #fff;
    background: -webkit-linear-gradient(#fff, #E6E7E8);
    background:    -moz-linear-gradient(#fff, #E6E7E8);
    background:         linear-gradient(#fff, #E6E7E8);
}
div#mainbottom h2{ 
    color: #3B7092; 
    text-align: left;
    margin: 0px;
    padding-top: 15px;
    padding-left: 55px;
    font-weight: normal;
}
div#mainbottom ul li{ 
    color: #F1F2F2; 
    text-align: center;
}
div#mainbottom img {
    float: left;
    border: none;
    display: in-line;
}
div#header img{
    position:absolute;
    top:20px;
    left:70px;
    zoom:0.9;
}

.span1{ width: 60px; }
.span2{ width: 120px; }
.span3{ width: 180px; }
.span4{ width: 240px; }
.span5{ width: 300px; }
.span6{ width: 360px; }
.span7{ width: 420px; }
.span8{ width: 480px; }
.span9{ width: 540px; }
.span10{ width: 600px; }
.span11{ width: 660px; }
.span12{ width: 720px; }
.span13{ width: 800px; }
.span14{ width: 880px; }
.left{
    float: left;
    position: relative;
}
.right{
    float: right;
    position: relative;
}
.center{
    margin-left: auto;
    margin-right: auto;
}

div.thirds {
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding: 0;
    height: auto;
    overflow: auto;
    zoom:0.9;
} 
div.thirds div.sleft{
    width: 165px;
    margin-right: 45px;
    padding: 10px;
    float: left;
    display: inline;
    color: #232323;
    overflow: auto;
}
div.thirds div.left,
div.thirds div.middle,
div.thirds div.right {
    width: 30%;
    margin-right: 0px;
    margin-left: 16px;
    padding: 10px;
    float: left;
    display: inline;
    color: #58595B;
    overflow: auto;
    height: auto;
}
div.thirds div.left div.sleft{
    margin-bottom:40px;
    top:0;left:0;
}
div.thirds div.right{margin-right:0;}

div.thirds div.pullout{
    width:540px;
    height:204px;
    float:right;
    margin-bottom:0px;
    padding-right:30px;
    text-align:right;
}

div.thirds div.wide {
    width: 920px;
    text-align: left;
    margin-left: 10px;
    margin-right: 0px;
    padding-left: 10px;
    min-height: 300px;
    height: auto;
    display: inline-block;
}
div.thirds div.wide2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 600px;
    text-align: left;
    float: left;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 320px;
    margin-right: 20px;
    padding: 20px 10px 0px 0px;
    height: auto;
}

div.thirds div.wide2 div.panel{
    width: 600px;
}
div.thirds div.wide div.panel{
    width: 920px;
}

div.wide2 ul li {
    padding: 0 0 10px 18px;
    background: transparent url('./assets/images/blue.png') 6px 6px no-repeat;
    overflow: auto;
}

div.wide ul { list-style-type: none; margin-bottom: 18px; }

div.wide ul li {
    padding: 0 0 10px 18px;
    background: transparent url('./assets/images/blue.png') 6px 6px no-repeat;
    overflow: auto;
}

div.wide ul li p {
    padding-bottom: 0;
}

div#content-1{
    min-height: 500px;
    padding: 0 20px;
}
/* header specific stuff */

div#header p{
   font-size:14px;
   width:200px;
   float:right;
   line-height:18px;
   color:#6D6E71;
   padding-top:20px;
   padding-bottom:8px;
   padding-right: 5px;
}

div#swatch, div#swatch_small {
   background-color: #AAA; 
   width: 100%; 
   min-width: 1250px;
   height: 223px; 
   text-align: center; 
   padding-top: 20px;
}
div#swatch > div{
    /*background:transparent url('../assets/images/web-banner1250x223.png');*/

    /*background:transparent url('../assets/images/x2.png');*/
    /*background:transparent url('../assets/images/header.jpg');*/
    /*background:transparent url('../assets/images/Oligo-web-banner-1250x223.png');*/
    height:225px; overflow: hidden; 
    background-size: cover; 
    background-position: center;
}

/* for small green header image*/
div#swatch_small {
   height:70px; 
   overflow:none;
}
div#swatch_small > div{
   border-top:4px solid #FFF;
   border-bottom:4px solid #FFF; 
   /*background:transparent url('../assets/images/header_small.jpg');*/
   background:transparent url('./assets/images/Oligo-web-banner-1250x40.png');
   height:61px; 
   background-size: cover; 
   background-position: center;
}

div#swatch_small > div.n{
   width: 1022px; 
   margin:0 auto; 
   border-top:4px solid #FFF;
   border-bottom:4px solid #FFF; 
   background:transparent url('./assets/images/header_small.jpg') top left no-repeat;
   height:61px;}

div#swatch_small div.ss {
    width: 90%;
    position: relative; 
    margin:auto;
    min-width: 960px;
    border-top: 3px solid #FFF;    border-bottom: 3px solid #FFF;
    height: 50px;
    background-size: contain;
    background-image: url("./images/header_small.gif");
}

/* top menu and sub nenu */

div#main {
    padding:0;
    overflow:auto;
    background:#FFF;
}

div#main ul.menu {
    overflow: auto;
    background-color: #EEE;
    list-style-type: none;
    padding: 0 20px;
}
div#main ul.menu li {
    display: block;
    float: left;
    padding: 10px 0;
    margin: 0 30px 0 0;
}
div#main ul.menu li.selected {
    border-bottom: 4px solid #E0E0E0;
}
div#main ul.menu li a {
    color: #000000;
    border-bottom-width: 1px;
}
div#main .loginlogout{
    float: right;
    padding-right: 30px;
}

div#main #main-search-box{
    width: 385px;
    position: relative;
    float: right;
    margin-right: 50px;
}
div#main #main-nav{ 
    width: 200px; 
    position: relative; 
    padding-top: 1px; 
    float: right; 
}

div#main div#left-menu {
    width: 210px; 
    margin: 15px 5px;
    padding: 10px 15px 5px 15px;
    float:left;
}
div#main div#right-content {
    margin: 5px 15px 0 245px;
    padding: 15px 10px;
    position: relative;
    width: auto; 
}

.wrapper {padding: 10px 20px;}

.admin-menu{
    background-color: #fff;
    width: auto;
    margin: 0 0 18px 0;
    padding: 0px;
    border:1px solid #3B7092;
}

div.admin-menu h4{
    font-weight:  bold;
    background-color: #80B0CA;
    text-align: center;
    padding: 5px 0px;
    margin: 0px;
}
div.store-menu{
    background-color: #fff;
    width: 215px;
    margin: 0 0 18px 0;
    padding: 0px;
    border:1px solid #5D8631;
}
div.store-menu h4{
    color: #fff;
    font-weight:  bold;
    background-color: #5D8631;
    text-align: center;
    padding: 5px;
    margin: 0px;
}
div#left-menu ul{
    margin: 0px;
    padding-bottom: 10px;
    padding-left: 10px;
}
div#left-menu ul li{
    margin: 0px;
    padding: 4px 4px;
    list-style-type: none;
    font-size: 12px;
}
a:link,a:visited {
    color: #3B7092;
    text-decoration: none;
    border-bottom: #9CDB1F 0px solid;
    font-weight: normal;
}
a:hover {
    border-bottom-color: #4b678e;
    color: #2aacf4;
}

h2 {
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 18px;
    color: #3B7092;
}

h3 {
    font-weight: normal;
    border-bottom: #B1DEFF solid 2px;
    padding-bottom: 4px;
    margin-bottom: 8px;
}

div#swatch img { 
    border-top: 4px solid #FFF; 
    border-bottom: 4px solid #FFF; 
}
div#swatch > div, div#swatch_small > div {
    width: 100%;
    margin:0 auto;
}

div#swatch div.banner h1{
    font-weight:bold;
    font-size:1.5em;
    margin-bottom:0.15em;
    letter-spacing:0.5px;
    color:#8cc63f;
}

div#swatch div.banner a{
    color: #8cc63f;
    font-size:1.05em;
    line-height:1.6em;
}
div#swatch div.banner p{
    color:#EEE;
    font-size:1.05em;
    line-height:1.6em;
}
div#swatch div.banner em{
    font-style:normal;
    border-bottom:3px solid rgba(255,255,255,0.2);
}

div#swatch div.popout div.banner {
    float: right;
    width: 350px;
    height: 110px;
    margin-top: 50px;
    margin-bottom: 20px;
    margin-right: 65px;
    padding: 10px 20px;
    background-color: rgba(29, 57, 78, 0.94);
    border-radius: 8px;
    border-color: rgba(255, 255, 255, 0.3);
    border-width: thin;
    border-style: solid;
}

div#swatch div.popout em {
    font-style: normal;
    border-bottom: 3px solid rgba(255,255,255,0.2);
}

div.error{
    padding: 10px;
    width: 700px;
    color: red;
}

img.filldown {float:right;}

.toptip{float:right;}

select.template_type{ width:90px;}
select.primer_conc{ width:50px;}
select.handling {width:110px;}
.example {color: teal;}

div#loginform { height: 600px; border: solid 1px #EEE; margin:auto;}

.rightlink{ float: right; 
    margin-right: 30px;
    font-size:12px;
    }
.label{
    background-color: #f1f2f1;
    font-style:  italic;
}
.error{ color: red; }
input,textarea,select{ border: 1px solid #999; }

input{height: auto; width:auto;}

.searchbox{
    background: #fdf5e6;
    padding: 20px 5px;
}


.suggestionsBox {
    /*position: absolute;*/
    /* top:20px; left:530px; height: 500px; */
    width: 400px;
    padding-top: 30px;
    background-color: lavender;
    border: 2px solid #000;
    color: black;
    font-size: 100%;
    z-index: 10;
    overflow: scroll;
}
.suggestionList {
    margin: 0px;
    padding: 0px;
}
.suggestionList li {
    margin: 0px 0px 3px 0px;
    padding: 3px;
    cursor: pointer;
}
.suggestionList li:hover {
    background-color: white;
    border: 1px solid gray;
    font-size: 150%
}

/*copy from aces.css*/
div.row,div.wrapper{ 
    width: auto; 
    padding: 5px 10px; 
    overflow: auto;
}

.multiselect {
    position: relative;
    width:auto;
    float: left;
    overflow:auto;
    border: 1px red dotted;
}
label{
    margin:  2px;
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
}
.control{ background: #5efb6e !important;}
.Done_QC{ background: #F5F590 !important; }
.Delivered{ background: #5EFB6E !important; }
.invalid_email{ background: pink !important; }
.express{ color: red !important; }
.online{ color: black; }
.multiselect-on{
    background-color: #97EC97;
}
.colheader {
    background: #fdf5e6;
}
tr.strikeout td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid #111;
  width: 100%;
}
tr.resolved td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 2px solid #111;
  width: 100%;
}
td.error{color: red !important;}
td.red_font{color: red !important;}
td.blue_font{color: blue !important;}
td.troublesome_1{background-color: Yellow !important;}
td.troublesome_2{background-color: Orange !important;}
td.troublesome_3{background-color: Red !important;}
td {
    padding:5px;
    padding-right:25px;
    font-size:12px;
    border-bottom:#D9DCE6 1px solid;
}
.copy {
    width: 16px;
    height: 16px;
    float: right;
    margin-right: 16px;
    background-image: url("./images/copy_16.png") ; 
    background-repeat: no-repeat;
}
.down {
    background-image: url("./images/down.png");
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
}
.flagged {
    background-image: url("./images/red_flag_16.png");
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
}
.collect{
    float: right;
    width: 16px;
    height: 16px;
    margin-right: 17px;
    background-repeat: no-repeat;
    background-image: url("./images/collect17.png");
}
.pencil{
    float: right;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    background-repeat: no-repeat;
    background-image: url("./images/pencil_16.png");
}
.pdf{
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-image: url("./images/pdf_16.png");
}
.upload{
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-image: url("./images/upload_16.png");
}
.move{
    float: right;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    background-repeat: no-repeat;
    background-image: url("./images/move_16.png");
}
/*NEW STYLE, cleanup template*/
.datafile {
    color:#630; 
    background:#ffc none; 
    font-size:120%; 
    font-family:Courier,monospace; 
    font-weight:bold;
}
#upload_data_selection{
    border:0px solid black; 
    background-color:#EEEEB5;
}

/* merge from default-min.css */
div.wide a:link, div.wide a:visited {
/*	border-bottom:#4b678e 1px solid;*/
}
div.thirds a:hover {
	opacity:0.8;
}
div.thirds div.service a.img:link, div.thirds div.service a.img:visited {
	border-bottom-width:0;
}
div#hidden {
	background:#D3E4F1;
	padding-top:36px;
}
div#main div.shadow {
	width:100%;
	height:4px;
	background:#E6E7E8;
}

div.service{
    padding:15px;
    background-color:#3B7092;
    border:#FFF 1px solid;
    border-right-width:0;
    border-left-width:0;
    color:#FFF;
    margin-bottom:21px;
}
div.service a:link, div.service a:visited{
    clear:left;
    color:#FFF; 
    text-decoration:none;
}
div.service img{
    margin-bottom:10px;
}

div#footer{
    clear:both;
    display:block;
    padding:15px 60px 5px 80px;
    margin: 0;
    background-color: #3B7092;
    height:200px;
}
div#footer p{
    width:900px;
    color:#ccc;
    float:left;
    font-size:12px;
    bottom:5px;
    margin-left: 50px;
}
div#footer div.fmenu {
    float: left;
    width: 180px;
    padding-right: 50px;
}


div.fmenu > a {
    color: white;
    font-size: 10px;
    font-weight: normal;    
}


div#footer a:link, div#footer a:visited, div#footer a:hover{
    border-bottom-width:0;
    display:block;
    font-size: 12px;
    font-weight: normal;
    color: #E6E7E8;
    line-height: 1.4em;
    padding-top: 5px;
}

div.half{
    width:443px;
    float:left;
    padding:10px;
    padding-right:30px;
    margin-bottom:26px;
}
div.half h1{
    letter-spacing:1px;
    margin-bottom:10px;
}
div.half h1 a:link, div.half h1 a:visited{
    border:0;
    color:#000;
}
div.half h1 a span{
    color:#AAA;
}
div.half h1 a:hover{
    border-bottom:1px solid #555;
}
h4{
    margin-bottom:8px;
}
table {
    border-collapse:collapse;
    border-spacing:0;
    margin-bottom:21px;
}
table.tablesorter thead tr th {
    color: green;
    background-color: #e6EEEE;
    border: 1px solid #FFF; 
    font-size: 12pt; 
    padding: 4px; 
}
table td em{ 
    font-weight:bold;
}
table th{
    border-bottom:#434343 1px solid;
    padding-right:20px;
}

div.wide th a:link, div.wide th a:visited{
    border-bottom:0px;
}
div.wide th span{
    font-size:10px;color:#9CDB1F;
}

.menuHead { 
    font-weight: bold; 
    font-size: 14px; 
    color: #FFFFFF;
} 
.menuChoices { 
    opacity: 0.8; 
    width: auto;
    position:relative;
    z-index:9999; 
    font-size: 12px;
} 
.menuChoices a {
    font-size: 12px;
}
.menu1 a {
    color: #000000; 
    text-decoration: none; 
    font-size: smaller;
}
.menu a {
    color: #FFFFFF; 
    text-decoration: none; 
    font-size: 12px;
}
.menu a:hover {
    text-decoration: underline; 
    background-color: #FFFFFF; 
    color: #000000} 
#menu_services {width: 120px; float:left}
#menu_products {width: 120px; float: left;} 
#menu_assays   {width: 120px; float: left;}
#menu_tools    {width: 100px; float: left;}
#menu_order    {width: 100px; float: left;}
#menu_news     {width: 100px; float: left;}
#menu_aboutus  {width: 120px; float: left;}
#menu_career   {width: 120px; float: left;}
#menu_login    {width: 160px; float: left; margin-left: 50px;}

table th td { 
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid black; 
}
table th{
   border-bottom:#434343 1px solid;
   padding-top: 5px;
   padding-bottom: 5px;
   padding-left: 5px;
   padding-right:25px;
   background-color: #3B7092;
   font-size: 16px;
   color: #fff;
}
table td {
   font-size: 14px;
}
.tdr { text-align: right; } 

table tr:nth-child(even) {
  background-color: #F1F2F2;
}
#wrap {
   width:920px;
   margin:0 auto;
}
#left_col {
   float:left;
   width:430px;
}
#right_col {
   float:right;
   width:430px;
}

nav {
	width: 100%;
	min-width: 1250px;
	height: 40px;
	margin-left:auto; 
	margin-right:auto;
	position: relative;
	text-align: center;
	z-index: 1000 !Important;
	background-color: #CCC;
	background: linear-gradient(top, #F7F7F7 5%, #D1D3D4 100%);  
	background: -moz-linear-gradient(top, #F7F7F7 5%, #D1D3D4 100%); 
	background: -webkit-linear-gradient(top, #F7F7F7 5%,#D1D3D4 100%); 
	white-space: nowrap;
	border-top: 1px solid #D1D3D4;
	border-bottom: 1px solid #D1D3D4;
}
nav ul ul {
	display: none;
}

nav ul li:hover > ul {
	display: block;
}
nav ul {
	background: #efefef; 
	background: linear-gradient(top, #F7F7F7 0%, #D1D3D4 100%);  
	background: -moz-linear-gradient(top, #F7F7F7 0%, #D1D3D4 100%); 
	background: -webkit-linear-gradient(top, #F7F7F7 0%,#D1D3D4 100%); 
	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.15);
	border-radius: 1px;  
	list-style: none;
	position: relative;
	display: inline-table;
	font-family: 'Helvetica', 'Verdana', 'Arial', sans-serif;
	font-size: 1.15em;
	margin:0 0 0 0;
	text-align:left;
}
nav a {
	border-left: 1px solid #D1D3D4;
	border-right: 1px solid #D1D3D4;
}
nav ul:after {
	content: ""; 
	clear: both; 
	display: block;
}
nav ul li {
	float: left;
	border-radius: 1px; 
}
nav ul li:hover {
	background: #4b545f;
	background: linear-gradient(top, #D1D3D4 0%, #BCBEC0 40%);
	background: -moz-linear-gradient(top, #D1D3D4 0%, #BCBEC0 40%);
	background: -webkit-linear-gradient(top, #D1D3D4 0%,#BCBEC0 40%);
}
nav ul li:hover a {
	color: #58595B;
}
	
nav ul li a {
	display: block; 
	padding: 8px 45px;
	color: #757575; 
	text-decoration: none;
	border-radius: 1px; 
}
nav ul ul {
	background: #FFF; 
	border-radius: 1px; 
	padding: 0;
	position: absolute; 
	top: 100%;
	opacity: .9 !important;
	font-size: 0.9em;
}
nav ul ul li {
	float: none; 
	border-bottom: 1px solid #575f6a;
	position: relative;
	border-radius: 1px; 
}
nav ul ul li a {
	padding: 6px 20px 6px 6px;
	color: #fff;
}	
nav ul ul li a:hover {
	background: #A7A9AC;
	color: #fff;
}
nav ul ul ul {
	left: 100%;
	top: 0;
	font-size: 1em;
}
li:hover > ul {
	display: block;
}
div.round img {
     border-radius: 8px;
     box-shadow: 0px 0px 10px #ccc; 
    -webkit-box-shadow: 0px 0px 10px #ccc;      
    -ms-box-shadow: 0px 0px 10px #ccc;
    -o-box-shadow: 0px 0px 10px #ccc;	
     vertical-align: middle;
     margin:20px 10px 10px 0;
     display: in-line;
     max-width: 95%;
}
div.round p {
     color: #2b2b2b;
     padding-left: 6px;
}
div.round h1 {
     padding-left: 4px;
}

div#footer div.social {
    float: right;
    width: 300px;
}
div#footer div.social img {
     border-radius: 4px;
     margin-right: 2px;
     float: left;
}
div#footer div.social a, div#footer div.social a:hover {
    opacity:0.8;
    display: inline;
}
div#main p {
        font-size: 1.15em;
        line-height: 1.3em;
}
div#mainbottom a {
        font-size: 1.1em;
        line-height: 1.3em;
}
div.qcbar {
    height:16px; width:60px; position: relative; overflow: hidden; 
    vertical-align: bottom;
}
div.qcsignal{
    height:16px; width:60px; position: relative; overflow: hidden; 
    vertical-align: bottom;
}
div.qcbar > div.qcbar_g {
    position: relative; background-color:#2D9414; float:left; height:100%;
}
div.qcbar > div.qcbar_k {
    position: relative; background-color:#32f89b; float:left; height:100%;
}
div.qcbar > div.qcbar_c {
    position: relative; background-color:#F9F928; float:left; height:100%;
}
div.qcbar > div.qcbar_f{
    position: relative; background-color:#FD3B3B; float:left; height:100%;
}

div.qcsignal > div.qcbar_ls {
    position: absolute; bottom:0px; background-color:#F9F928; height:50%;
}
div.qcsignal > div.qcbar_os {
    position: absolute; bottom:0px; background-color:#F96B06; height:100%;
}
div.qcsignal > div.qcbar_nm{
    position: absolute; bottom:0px; background-color:#2D9414; height:100%;
}
div.qcsignal > div.qcbar_f{
    position: absolute; bottom: 0px; background-color:#FD3B3B; height:10%;
}
div.promwrapper {
    width: 420px;
    margin-left: 120px;
}
div.promwrapper div.prom {
    width: 170px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    border: 4px #44de7c solid;
    padding: 10px;
    font-weight: bold;
}
div.promwrapper div.prom p, div.promwrapper div.prom ul {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
div.promwrapper div.prom ul{
    padding-left: 20px;
}

div.promwrapper h4{
    color: navy;
    font-weight: 900;
}
a.prommore{
    color: #1cab50;
    font-weight: bold;
    padding-left: 60px;
}



.customer_summary_box {
    border-color: #3b7092;
    border-style: solid;
    border-width: 1px;
    border-radius: 16px;
    padding: 16px;
    background-color: #eaecef; 
}

.customer_summary_box h2 {
    width: 200px;
    text-align: center;
    margin: auto;
    margin-bottom: 16px;
    border-bottom: solid 1px #3B7092;
}

ul.customer_summary_list li {
    list-style-type: none;
    font-size: 16px;
    padding: 2px;
}

ul.customer_summary_list li:empty {
    display: none;
}


div.thirds_pickup {
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding: 0;
    height: auto;
    overflow: auto;
    zoom:1;
} 

div.wide_pickup {
    width: 90%;
    text-align: left;
    margin-left: 10px;
    margin-right: 0px;
    padding-left: 10px;
    min-height: 240px;
    height: auto;
    display: inline-block;
    zoom:0.9;
}

div.pickup_tab_text p {
    font-size:1em !important;
}

button.lab_pickup_schedule_btn {
    border: 1px solid #c5dbec;
    background: #dfeffc url(./images/ui-bg_glass_85_dfeffc_1x400.png) 50% 50% repeat-x;
    color: #2e6e9e;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

tr.naf {
    background-color: white !important;
}

tr.af {
    background-color: #b8cedc !important;
}

ul.qb_breadcrumb {
    padding: 10px;
    list-style: none !important;
    font-size: 14px;
}
ul.qb_breadcrumb li {

    padding: 0 !important;
    display: inline; 
    background: none !important;
}
ul.qb_breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
}
ul.qb_breadcrumb li a {
    color: #0275d8;
    text-decoration: none;
}
ul.qb_breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
}

div.f_right {
    float: right;
}

div.f_left {
    float: left;
}

table.protein_product_table {
    width: 100%;
}

table.protein_product_table tr {
    height: 50px; 
}

tr.protein_catalog_row > td {
    vertical-align: middle !important;
    height: 50px;
}

ul.protein_category_filter > li {
    display: inline;
    background: none !important;

}

/* Q-Block Gene Fragment */
ul.qblock_list {
    font-size: 1.15em;
}

span.qblock_span_color {
    color: #39b147;
    font-weight: bold;
}

div.gene_fragments_box {
    background-color: #eef7ed;
    padding: 24px;
    border-radius: 20px;
    width: 65%;
}

div.qblock_container ul li {
    padding: 0 0 10px 6px;
    margin-left: 36px;
    background: initial;
    list-style-type: initial;
    overflow: initial;
}

div.qblock_container_advantages ul li {
    padding: 0px 5px 10px 26px;
    background: initial;
    overflow: initial;
    background: transparent url(./assets/images/Q-Block-check-mark-7x6.png) no-repeat;
    background-size: 2%;
    margin-bottom: 5px;
}

input.qblock_order_btn:hover {
    opacity: .85;
}

a.service_link:link {
    border-bottom: initial !important;
}

a.service_link:hover {
    opacity: .82;
}

img.service_icon {
    margin-right: 0px !important;
}

div.q-block_banner {
    cursor: pointer;
}

div.q-block_banner:hover {
    background-color: rgba(29, 57, 78, 0.86) !important; 
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.85);
}

span.q-block_banner_cost {
    color: #ca270b;
    font-size: 1.6em;
}

ul.banner_list li {
    color: white;
    margin: 0 0 5px 30px;
    
}

div.q-block_login {
    width: 350px;
    height: 110px;
    padding: 10px 15px 10px 15px;
    background-color: rgba(29, 57, 78, 0.94);
    border-radius: 8px;
    margin: 20px auto 20px auto;
}

div.q-block_banner h1 {
    font-size: 1.3em !important;
    color: #8cc63f;
}
div.q-block_login h1 {
    font-size: 1.5em !important;
    color: #8cc63f;
}

div.q-block_login h1.banner_service {
    color: #7dd9ff !important;
}

div.q-block_banner h1.banner_service {
    color: #7dd9ff !important;
}

div.login_error {
    color: #ea0000;
    background-color: #ffdede;
    width: initial;
    border-radius: 6px;
    margin: 0 0 20px 0;
    padding: 10px 0 10px 8px;
}


/* jQueryUI */
.ui-autocomplete {
    max-height: 500px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}

* html .ui-autocomplete {
    height: 200px;
}


div.error_div {
    color: #ea0000;
    background-color: #ffdede;
    width: initial;
    border-radius: 6px;
    margin: 0 0 20px 0;
    padding: 10px 0 10px 8px;
}

div.success_div {
    color: #3c763d;
    background-color: #dff0d8;
    width: initial;
    border-radius: 6px;
    margin: 0 0 20px 0;
    padding: 10px 0 10px 8px;
}

div.news_col_l {
    width: 30%;
    float: left;
}
 
div.news_col_r {
    width: 65%;
    float: right;
}
 
div.news_col_l div {
    height: 350px;
}
 
div.news_col_r div {
    height: 350px;
}
 
div.news span.title {
    font-size: 1.5em;
    line-height: 1.7em;
}
 
div.news p {
    line-height: 1.7em !important;
}

.clip {
    max-width: 50px;	
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .clip:hover{ max-width: initial; }*/


.d_block {
    display: block;
}

.nowrap {
    white-space: nowrap;
}

/* dna prep order form */
#dna_prep_order_form div.f_left {
    float: left;
    width: 50%;
    padding-right: 80px;
    box-sizing: border-box;
}

#dna_prep_order_form input[type=text], #dna_prep_order_form textarea, #dna_prep_order_form select {
    width: 100%;
    border-radius: 4px;
    box-sizing : border-box;
    padding: 5px 10px;
}

#dna_prep_order_form input[type=file] {
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
}

div.dna_prep_order_form_section {
    margin-bottom: 30px;
}

div.dna_prep_order_form_section table.dna_prep_catalog {
    width: 100%;
}

div.dna_prep_order_form_section > label {
    display: block;
    padding-bottom: 6px;
}

div.dna_prep_order_form_section > label.dna_prep_order_form_section_title {
    font-style: unset;
}

div.dna_prep_order_form_section > input[type=text], div.dna_prep_order_form_section > select {
    display: block;
}

.dna_prep_order_form_section input[type=text]#other_antibiotic_resistance {
    display: none;
    margin-top: 20px;
}

.dna_prep_order_form_section #starting_mat_other .sm_left {
    width: 80px;
    float: left;
}

.dna_prep_order_form_section #starting_mat_other .sm_right {
    margin-left: 80px;
}

.seq_primers > div.seq_primers_l {
    width: 120px;
    float: left;
}

.seq_primers > div.seq_primers_r {
    margin-left: 120px;
    margin-bottom: 20px;
}

.dna_prep_order_form_section #starting_mat_other .sm_right input[type=text] {
    width: 100%;
}

/*
div#enzyme_selection {
    max-height: 140px;
    overflow: auto;
    border: 1px solid gray;
    padding: 5px 0 0 5px;
}

div#enzyme_selection input[type=checkbox] {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
}

div#enzyme_selection label {
    display: inline-block;
    width: 92%;
    padding: 3px 0 3px 5px;
}

.b_border {
    border-bottom: 1px solid lightgray;
}
*/

.required {
    color: red;
}

form#dna_prep_order_form .error {
    border-color: red;
}

form#dna_prep_order_form label.error {
    font-weight: unset;
    height: 28px;
}

form#dna_prep_order_form select {
        color: unset !important;
}

form#dna_prep_order_form input[type=text]::placeholder, form#dna_prep_order_form textarea::placeholder {
    color: lightgray;
}

/*
.seq_primers {
    margin-left: 30px;
}
*/

form#dna_prep_order_form table#dna_prep_order_table thead tr th, form#dna_prep_order_form table#dna_prep_order_table tbody tr td {
    border: 1px solid #ccc;
    height: 30px;
}

form#dna_prep_order_form table#dna_prep_order_table thead tr th {
    font-size: 12px;
    font-weight: 400;
    padding: 5px 15px;
    text-align: center;
}

form#dna_prep_order_form table#dna_prep_order_table tbody tr td {
    padding: 0;
}

table#dna_prep_order_table input[type=text], table#dna_prep_order_table textarea, table#dna_prep_order_table select {
    height: 28px;
    /*height: 100%;*/
    /*min-height: 26px;*/
    /*max-height: 34px;*/
    border: none;
    border-radius: 0px !important;
    background-color: transparent;
    padding: 2px 4px;
    font-size: 12px;
}

table#dna_prep_order_table label.error {
        display: block;
}

div#dna_prep_order_preview_customer_info {
    margin-bottom: 20px;
}

div#dna_prep_order_preview_customer_info span.label {
    width: 160px;
    display: inline-block;
}

table.dna_prep_order_preview {
    width: 100%;
    margin-bottom: 0;
}

table.dna_prep_order_preview thead tr th {
    color: green;
    background-color: #e6EEEE;
    border: 1px solid #FFF;
    font-size: 8pt;
    padding: 4px;
}

table.dna_prep_order_preview tbody tr td {
    height: 16px;
}

table.dna_prep_order_preview tbody tr.total_price_row {
    height: 50px;
    font-weight: bold;
}

hr.dna_prep_order_divider {
    margin: 26px 0;
}

div.dna_prep_plasmid_details, div.dna_prep_order_summary {
    border: solid 1px #5d8631;
}

.pre-wrap {
    white-space: pre-wrap;
}

.ta_center {
    text-align: center;
}

.ta_right {
    text-align: right;
}

.f_right {
    float: right;
}

.f_left {
    float: left;
}

div#swatch_top_banner {
    height: 230px;
}

div#swatch_top_banner > div {
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
}

div#swatch_top_banner div#top_banner_title_container {
    font-size: 42px;
}

span#top_banner_title_text {
    display: inline-block;
    color: white;
    margin-left: -1000px;
    padding: 20px 20px 20px 1000px;
}

div#swatch {
    background-image: url(./assets/images/competentcells_banner.jpeg);
}

/*
body.competentcells div#swatch_top_banner > div {
    background-image: url(./assets/images/competentcells_banner.jpeg);
}
*/

body.competentcells span#top_banner_title_text {
    /*background-color: #a991898c;*/
    background-color: rgba(169, 145, 137, 0.55);
}

.product_order_table {
    background-color: #f4f4f4;
    padding: 1em 2.5em;
    width: 100%;
}

.product_order_table input[type=number]::-webkit-inner-spin-button, .product_order_table input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none; 
    margin: 0;
}
.product_order_table input[type=number] {
    text-align: center;
    width: 3em;
}
.product_order_table input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.product_order_row {
    height: 3em;
    padding: .2em 0;
}

.product_order_row .header_section {
    font-size: 1.2em;
    font-weight: bold;
}

.product_order_row .header_section, .product_order_row .data_section {
    padding: 0 1em;
}

.product_order_row .product_header,.product_order_row .product_data {
    flex: 6 1 0;
    font-weight: bold;
}
.product_order_row .size_header, .product_order_row .size_data {
    flex: 12 1 0;
}
.product_order_row .quantity_header, .product_order_row .quantity_data {
    flex: 3 1 0;
    white-space: nowrap;
}
.product_order_row .submit_header, .product_order_row .submit_data {
    flex: 5 1 0;
}

.product_order_row .size_data select {
    width: 100%;
}

.product_order_row .quantity_data input[type=number], .product_order_row .size_data select {
    height: 22px;
}
.product_order_row .quantity_data input[type=button] {
    margin: 0;
    padding: 0;
    width: 26px;
    height: 24px;
    vertical-align: bottom;
    border-color: #999999;
    background: none;
    background-color: #e0e0e0;
    color: black;
}
.product_order_row .quantity_data input[type=button]:hover{
    background-color: #b3b3b3;
}
.product_order_row .quantity_data input[type=button].minus_btn {
    border-right: none;
    border-radius: 4px 0 0 4px;
}
.product_order_row .quantity_data input[type=button].plus_btn {
    border-left: none;
    border-radius: 0 4px 4px 0;
}


#top_banner_title_text {
    font-family: D-DINCONDENSED_0;
    font-weight: bold;
    font-size: 1.4em;
}